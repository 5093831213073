<!-- 动物样本库 -->
<template>
  <div class="animalBank">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'模式动物管理系统'" />
    <!-- 行业概述 -->
    <Business :business="business" />
    <!-- 系统功能 -->
    <div class="system-container">
      <div class="system">
        <div class="system-left">
          <Subhead :enVal="'Applications'" :znVal="'应用场景'" :isBlue="1" />
          <div class="system-left-text">{{ systemData.text | myTrim }}</div>
        </div>
        <img :src="systemData.imgUrl" alt="" class="system-rihgt">
      </div>
    </div>
  </div>
</template>
  

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import Subhead from "./components/Subheading.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    Subhead,
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner12.png"),
      business: {
        text: "在生命科学、人类医药和健康研究领域，实验动物在生命活动中的生理和病理过程，与人类或异种动物都有很多相似之处，并可互为参照。将实验动物进行造模，建立人类疾病的动物模型，对分析疾病的发病机制，解答特定人群对某种疾病的易感性，开发新型药物开发有着重要的作用。研究中需要采集模式动物的各种类型样本供实验研究之用。模式动物样本信息管理系统涵盖实验动物生产管理子系统，模式动物信息数据管理子系统和模式动物样本管理子系统，实现动物实验全生命周期的质量控制和溯源要求，保证动物实验的科学性、准确性和重复性。",
        Imgs: [
          {
            url: require("@/assets/images/cases/content/animalBank01.png")
          },
          {
            url: require("@/assets/images/cases/content/animalBank02.png")
          }
        ]
      },
      systemData: {
        text: "系统采用云计算技术，配合智能监控系统对实验动物的生产、生物学特性 数据、造模实验，以及模型动物生物样本的采集、存储、使用等进行信息 化管理，系统功能模块包括实验动物档案管理、智能动物房管理、实验动 物福利伦理管理、模型数据管理、检测数据管理，模型动物生物样本库管 理，以及研究项目管理等。系统遵循ISO 20387标准，对模式动物样本的 采集、存储、出入库等实现规范化、标准化管理。系统具有强大的数据统 计分析功能和权限管理功能，支持移动端应用。该系统应用于粤港澳联合 实验室模型动物管理，支撑中医湿证临床转化与治疗研究。",
        imgUrl: require("@/assets/images/cases/content/system-animalBank01.png")
      }
    }
  },
}
</script>

<style lang="less" scoped>
.system-container {
  max-width: 1920px;
  background: #F0F3F9;
  margin: 0 auto;

  .system {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .system-left {
      .system-left-text {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        margin-top: 54px;
        letter-spacing: 1px;
        text-align: justify;
      }
    }

    .system-rihgt {
      display: block;
      width: 687px;
      height: 483px;
      margin: 61px 0 61px 84px;
    }
  }

}
</style>